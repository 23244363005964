<template>
  <b-navbar v-b-scrollspy:scrollspy-nested class="flex-column text-left index-column">
    <div class="ta-center index-title" >
      <span class="fz-18 openSans-400">{{$t('mgm.index')}}</span>
      </div>
    <b-nav class="w-100" pills vertical>
      <document-index-node v-for="node in indexData.childs" v-bind:key="node.serial" :node="node"></document-index-node>
    </b-nav>
  </b-navbar>
</template>

<script>
import DocumentIndexNode from '@/modules/common/components/DocumentIndexNode.vue'

export default {

  name: 'DocumentIndex',
  components: { DocumentIndexNode },
  props: {
    passages: Array
  },
  data () {
    return {
      indexData: {
        data: null,
        parent: null,
        childs: []
      },
      showIndex: true
    }
  },
  methods: {
    buildIndex() {
      var indexLvl = 0
      var currentNode = {}
      this.passages.forEach(passage => {
        if (passage.heading) {
          let lvl = passage.heading.match(/\d+/)
          lvl = lvl ? lvl[0] : 0
          let passage_text = passage.html
          if (passage_text.indexOf('\\n') > -1) {
            passage_text = passage_text.substr(0, passage_text.indexOf('\\n'))
          }

          if (lvl == indexLvl) {
            var newNode = {
              passageId: `passage_${passage.serial}`,
              label: passage_text,
              serial: passage.serial,
              lvl: indexLvl,
              childs: []
            }
            if (currentNode.parent) {
              newNode.parent = currentNode.parent
            } else {
              newNode.parent = this.indexData
            }
            newNode.parent.childs.push(newNode)
            currentNode = newNode
          }
          else if(lvl > indexLvl) {
            indexLvl += 1
            let newNode = {
              passageId: `passage_${passage.serial}`,
              label: passage_text,
              serial: passage.serial,
              lvl: indexLvl,
              childs: [],
              parent: currentNode
            }
            currentNode.childs.push(newNode)
            currentNode = newNode
          } else {
            indexLvl -= 1
            let newNode = {
              passageId: `passage_${passage.serial}`,
              label: passage_text,
              serial: passage.serial,
              lvl: indexLvl,
              childs: [],
              parent: currentNode.parent.parent
            }
            newNode.parent.childs.push(newNode)
            currentNode = newNode
          }
        }
      })
    }
  },
  created() {
    this.buildIndex()
  }
}
</script>

<style lang="scss" scoped>

  .index-title {
    margin-bottom: 10px;
    color: var(--ocean-blue);
  }
  .index-column {
    max-height: 94vh;
    height: auto;
    min-height: 456px;
    overflow: scroll;
    overflow-x: hidden;
  }

  ::v-deep  {

    ul.nav-pills div {

    li.nav-item a.nav-link {
      padding: 0;

      span {
        color: var(--ocean-blue);
        padding-left: 0px; 
        font-size: 14px;
        font-weight: 600;
        font-family: "OpenSans";
      }
  
      span:hover {
        color: var(--primary)
      }

      img[aria-expanded="true"]{
        transform: rotate(-180deg);
    }

    }

    ul.nav-pills li.nav-item a {
    margin-bottom: 0px;

      li.nav-item a.nav-link {
        padding: 4px 0 4px 16px;

        span {
          color: var(--ocean-blue);
          padding-left: 0px; 
          font-size: 14px;
          font-weight: 300;
          font-family: "OpenSans";
        }

        span:hover {
          color: var(--primary)
        }
      }

      ul.nav-pills li.nav-item a {
      margin-bottom: 0px;

        li.nav-item a.nav-link span {
          padding: 0px 0px 0px 16px;
          color: var(--ocean-blue);
          padding-left: 0px; 
          font-size: 14px;
          font-weight: 400;
          font-family: "OpenSans";
        }

        li.nav-item a.nav-link span:hover {
          color: var(--primary)
        }

        ul.nav-pills li.nav-item a {
        margin-bottom: 0px;

          li.nav-item a.nav-link {
            padding: 0px 0px 0px 16px;

            span {
              color: var(--ocean-blue);
              padding-left: 0px; 
              font-size: 14px;
              font-weight: 100;
              font-family: "OpenSans"; 
            }

            span:hover {
              color: var(--primary)
            }
            
          }
        }
      }
    }
  }
      
  }
</style>