<template>
  <div class="margin-dots my-auto float-right comment-button">
    <button class="button-secondary feedback-popover-button pt-0" :id="`popover-${ passage.serial }`">
      <img :src="'/assets/images/icon_feedback.svg'"/>
      <span class="openSans-300 fz-16" v-if="passage.feedback.length > 0">
        {{ passage.feedback.length }}
      </span>
    </button>
    <b-popover :ref="`popover-${ passage.serial }`" triggers="hover focus" @shown="scrollToBottom"
               boundary-padding="2" custom-class="feedback-popover" :target="`popover-${ passage.serial }`" placement="bottom" class="p-0">
      <div class="">
        <div ref="feedbacksContainer" class="overflow-chat overflow-auto">
          <div v-for="comment in feedbackFormattedToShow" :key="comment.id" class="d-flex mt-3 ">
            <div class="d-flex col-12 text-balloon" :class="comment.ownComment ? 'justify-content-end ' : ''"  >
              <div class="col-10">
                <div class="col-12 bd-highlight author"  :class="comment.ownComment ? 'chat-self-comment-author ' : 'chat-other-comment-author'">
                    <span class="">{{ comment.fullName ? comment.fullName : comment.author}}</span>
                </div>
                <div class="col-12" :class="comment.ownComment ? 'chat-self-comment ' : 'chat-other-comment'" >
                    <div  class="text-balloon">
                      <span>{{ comment.text }}</span>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <div class="row">
            <div class="col-12">
              <b-form-textarea class="openSans-100 fz-15 input-chat-text-area input-margin note-popover-textarea"
                               v-model="inputComment" :placeholder="$t('knowledge.ask.notes.popup.inputPlaceholder')" autofocus>
              </b-form-textarea>
            </div>
            <div class="col-12">
              <button class="button-input" :disabled="!inputComment" @click="saveFeedback()">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import LibraryService from "@/modules/library/services/LibraryService";
import store from "@/store";
import moment from 'moment'

const $ = require('jquery')
window.$ = $

export default {
  name: 'feedback-popover',
  props: {
      passage: Object
  },
  data() {
      return {
          inputComment: '',
          feedbackFormattedToShow: [],
          currentUser: null
      }
  },
  methods: {
      saveFeedback() {
        let today = moment.utc().format()
        var newFeedback = {
              text: this.inputComment,
              author: store.getters.getCurrentUser.userid,
              created: today,
              fullName: this.$auth.user.name ? this.$auth.user.name :
                                               store.getters.getCurrentUser.userid
        }
        var newFeedbackFormatted = this.formatFeedback(newFeedback)
        this.passage.feedback.push(newFeedback)
        this.feedbackFormattedToShow.push(newFeedbackFormatted)
        this.saveComments(this.passage.feedback)
        this.inputComment = ''
      },
      deleteFeedback(comment) {
        const index = this.passage.feedback.indexOf(comment)
        if (index > -1) {
          this.passage.feedback.splice(index, 1)
        }
        this.saveComments(this.passage.feedback)
      },
      saveComments(feedback){
        let passageData = {
          passage_id: this.passage.id,
          feedback: feedback
        }

        let params = this.$router.currentRoute.params
        LibraryService.updatePassageFeedback(passageData, params.bot).then(()=> {
          this.scrollToBottom()
        })
      },
      scrollToBottom() {
        this.$refs.feedbacksContainer.scrollTop = this.$refs.feedbacksContainer.scrollHeight
      },
      updateFeedbackFormatted(){
        var feedback = this.passage.feedback;
        this.feedbackFormattedToShow = feedback.map(this.formatFeedback)
      },
      formatFeedback(feedback){
        var feedbackToShow = Object.assign({}, feedback)
        feedbackToShow.ownComment = feedback.author == this.currentUser
        feedbackToShow.author = feedback.author.split("|")[1]
        return feedbackToShow
      }
  },
  created() {
    this.currentUser = store.getters.getCurrentUser.userid
    this.updateFeedbackFormatted()
  }
}
</script>
<style lang="scss" scoped>

input[type="button" i], input[type="submit" i], input[type="reset" i], input[type="file" i]::-webkit-file-upload-button, button {
    padding: 0px 6px;
}

@media print {
      

    .feedback-popover{
      display:none
    }

    .zero-feedbacks{
      display:none !important;
    }
}

.comment-button {
  line-height: normal
}

.text-balloon{
  padding-left: 10px;
  padding-right: 0px
}

.author{
  font-weight: bold; 
}

.overflow-chat{
  max-height:400px
}

.feedback-popover-button {
  margin-left: -1px;
  border-radius: 0px 6px 6px 0px !important;
  border: unset !important;
  min-width: 40px !important;
  height: 26px !important;
}

.icon-back {
    width: 53px;
    height: 24px;
    padding-left: 0px;
    margin-bottom: 10px;
    margin-left: -5px
}

.comment-row {
    padding-bottom: 10px;
    border-bottom: 1px solid #a3a3a3
}

.icon-delete {
    width: 20px;
    height: 20px;
}

.chat-self-comment {
  border-radius: 10px;
  background: #F1F3F6;
  padding: 10px;
}

.chat-other-comment {
  border-radius: 10px;
  background: #E8F7F0;
  padding: 10px;
}

.chat-self-comment-author {
  padding-right: 10px;
  text-align:right
}

.chat-other-comment-author {
  padding-left: 10px;
  text-align:left
}

.note-popover-textarea {
  border: 1px solid #ced4da;
  padding: 10px 10px;
  min-height: 150px;
  margin-bottom: 14px;
}

.input-chat-text-area {
  background: #F1F3F6;
  color:black;
  min-height:50px;
  border-radius:0;
  max-height:120px;
  margin-bottom: 0px;
  border:0px
}

.button-input {
    min-width: unset !important;
    width: 100% !important;
    height: auto !important;
    background: white;
    color: var(--viridian);
    margin-top:5px;
    border:0px
}

.project-action-list li {
  list-style-type: none;
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 5px;
}

.project-action-list li span {
  vertical-align: sub;
  margin-left: 19px;
  color: var(--viridian);
  font-size: 15px;
}

.padding-zero {
  padding: 0px;
}

.feedback-popover{
  min-width: 340px;
  min-height: 120px;
  height: auto;
  border: 0px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}



</style>