<template>
  <div class="tags-visible d-flex px-0 ">
    <b-card class="mb-2 passage-mgm-card col-11 px-0" align="left">
      <div class="mb-4 d-flex justify-content-between ta-right mgm-card-header">
      <div class="d-flex">
        <favourite-button :passage="passage" @callback="favoriteCallback"></favourite-button>
        <span class="passage-serial vertical-align">Passage {{passage.serial }}</span>
      </div>
      <div class="align-items-center my-1">
        <feedback-popover :passage="passage"></feedback-popover>
        <span class="category-chip fz-14 openSans-400 d-inline-flex" v-for="category in categories" :key="category.metatag"
              :class="isSelectedCategory(category.metatag) ? 'selected-category-chip': ''">
          {{category.metatag.replace(/_/g," ")}}
          <span class="fz-14 ml-2 openSans-300" v-if="isSelectedCategory(category.metatag)"> {{category.sensitivity}}%</span>
        </span>
      </div>
      </div>
      <div class="row">
        <MarkdownToHtml class="col" :source="taggedPassage.text" :docId="taggedPassage.doc_id"></MarkdownToHtml>
        <feedback-print :passageSerial="passage.serial"
                        :passage="passage"></feedback-print>
      </div>
    </b-card>
  </div>
</template>
<script>

import FeedbackPopover from '@/modules/medicalGuidelineManagement/components/FeedbackPopover'
import FavouriteButton from '@/modules/ivdr/components/FavouriteButton'
import MarkdownToHtml from '@/modules/common/components/MarkdownToHtml'
import FeedbackPrint from '@/modules/medicalGuidelineManagement/components/FeedbackPrint'

export default {
  name: 'DocumentPassage',
  components: {FeedbackPopover, FavouriteButton, MarkdownToHtml, FeedbackPrint},
  props: {
    passage: Object,
    selectedTags:Array,
    selectedGroupTags:Array,
    groupTagRangeSelector: Array,
    selectedCategories: Array,
    showTags: Boolean,
    favoriteCallback: Function
  },
  data() {
    return {
      categories: [],
      favorite: false,
      initialised: false,
      taggedPassage: Object
    }
  },
  methods: {
    getCategories(){
      this.categories = Object.entries(this.passage.predictioScores).map(([key,value]) => {
            value = Math.round(value * 100)
            return ({metatag: key, sensitivity: value})
      })
    },
    isSelectedCategory(category) {
      let selected = this.selectedCategories.filter(cat => cat.value == category)
      return selected.length != 0
    },
    removeOverlapping(spans) {
      spans.sort((a, b) => {return -1 * this.sortByPrecedence(a, b)})
      let winners = []
      for (const span of spans) {
        if (!winners.some(winner => this.spansOverlap(winner, span))) {
          winners.push(span)
        }
      }
      winners.sort(function (a,b) {
        return b.spanEndCharacter - a.spanEndCharacter
      } )
      return winners
    },
    sortByPrecedence (a,b) {
      // simplify
      let scoreDifference = a.predictionScore - b.predictionScore
      if (scoreDifference != 0) {
        return scoreDifference
      } else {
        return a.text.length - b.text.length
      }
    },
    spansOverlap(a,  b) {
      let bs = b.spanStartCharacter
      let be = b.spanEndCharacter
      this.log(`checking overlap between ${JSON.stringify(a)} and ${JSON.stringify(b)}`)
      let overlap = !(b == undefined || a ==undefined)
          && (this.isBetween(a.spanStartCharacter, bs, be) || this.isBetween(a.spanEndCharacter, bs, be))
      this.log(`overlap? ${overlap}`)
      return overlap
    },
    isBetween(n, a, b) {
      return (n - a) * (n - b) <= 0
    },
    log(text){
      // we use this for debugging purposes
      if (this.passage.serial == -1) {
        console.log(text)
      }
    },
    shiftSpansForCharReplacement(tempText, a, b, spans) {
      let charsToReplace = this.indexes(tempText, a)
      let shiftedSpans = spans
      if (charsToReplace.length > 0) {
        shiftedSpans = []
        spans.forEach((e) => {
          if (e != undefined) {
            let startShiftFilter = charsToReplace.filter(s => s < e.spanStartCharacter)
            let startShift = startShiftFilter.length * (b.length - a.length)
            let endShift = charsToReplace.filter(s => s < e.spanEndCharacter).length * (b.length - a.length)
            if (startShift > 0) {
              e['spanStartCharacter'] = e.spanStartCharacter + startShift
            }
            if (endShift > 0) {
              e['spanEndCharacter'] = e.spanEndCharacter + endShift
            }
          }
          shiftedSpans.push(e)
        })
      } else {
        this.log(`no chars to replace`)
      }
      return shiftedSpans

    },
    replaceHtmlTagChars(spansAndText, a, b) {

      spansAndText['spans'] = this.shiftSpansForCharReplacement(spansAndText.text, a, b, spansAndText.spans)
      let regex = new RegExp(a, 'g');
      spansAndText['text'] = spansAndText.text.replace(regex, b)
      return spansAndText
    },
    tagPassage(spans) {
      let passage = this.taggedPassage
      let tempText = passage.html
      this.log('BEFORE TAGGING')
      this.log(tempText)
      spans.forEach((e) => {
        if (e != undefined && tempText.substring(e.spanStartCharacter, e.spanEndCharacter).indexOf('|') == -1) {
          let prefix = tempText.substring(0, e.spanStartCharacter)
          let concept = tempText.substring(e.spanStartCharacter, e.spanEndCharacter)
          let suffix = tempText.substring(e.spanEndCharacter, tempText.length)
          let styleClass = e.category
          let weight = e.predictionScore * 100
          let visibility = this.showTags ? "" : "tags-invisible"
          this.log(concept)
          tempText = `${prefix}<span class="li-tagged-text ${styleClass} ${visibility}" aria-valuetext="${e.synonym}" title="${styleClass.replace(/_/g, " ")} : ${Math.trunc(weight)}% ">${concept}</span>${suffix}`
        }
      })
      passage.text = tempText
      this.taggedPassage = passage
    },
    indexes(source, find) {
      //returns all indexes of a regex into a string
      if (!source) {
        return [];
      }
      if (!find) {
        return [];
      }
      let regex = new RegExp(find, 'g');
      let result = [];
      let match
      while ((match = regex.exec(source)) != null) {
        result.push(match.index);
      }
      return result;
    },
    filterSpans() {
      if (!this.initialised) {
        this.log("not tagging until initialised")
        return
      }
      let spans = this.taggedPassage.spans
      if (spans != undefined) {
        this.log(`filtering by score  >= ${this.groupTagRangeSelector[0]}  &&  <= ${this.groupTagRangeSelector[1]}`)
        spans = spans.filter(e => (this.nothingFiltered
          || (this.selectedGroupTags.indexOf(e.category) > -1
            && e.predictionScore * 100 >= this.groupTagRangeSelector[0]
            && e.predictionScore * 100 <= this.groupTagRangeSelector[1])
          || this.spanIsInSelectedTags(e))
          && (e.spanEndCharacter - e.spanStartCharacter > 0))
        this.markSpans(spans)
        this.logSpans(spans)
        spans = this.removeOverlapping(spans)
        this.log('AFTER DEDUPLICATION')
        this.logSpans(spans)

        this.tagPassage(spans)
      }
    },
    spanIsInSelectedTags(span) {
      return this.selectedTags && this.selectedTags.some(tagData => tagData.classes == span.category
        && ((span.synonym && tagData.type == "tag" && tagData.text == span.synonym.toLowerCase())
          || (span.text && tagData.type == "text" && tagData.text == span.text.toLowerCase())))
    },
    markSpans(spans) {
      let serial = 0
      spans.forEach(e => {
        e['serial'] = ++serial
      })
    },
    logSpans(spans) {
      if (spans != undefined) {
        for (const e of spans) {
          this.log(JSON.stringify(e))
        }
      }
    },
  },
  created() {
    this.getCategories();
    this.taggedPassage = this.passage
    if (this.taggedPassage.spans != undefined) {
      this.taggedPassage.spans.sort((a,b) => {
        let alength = a.spanEndCharacter - a.spanStartCharacter
        let blength = b.spanEndCharacter - b.spanStartCharacter
        if (a.spanEndCharacter > b.spanEndCharacter) {
          this.log(`resolved order by end char ${a.spanEndCharacter} vs ${b.spanEndCharacter}  (-1)`)
          return -1
        } else if (a.spanEndCharacter < b.spanEndCharacter) {
          this.log(`resolved order by end char ${a.spanEndCharacter} vs ${b.spanEndCharacter}  (1)`)
          return 1
        } else if (alength > blength)  {
          this.log(`resolving order by length for span ending at ${a.spanEndCharacter} (${alength} vs ${blength}) (-1)`)
          return -1
        } else if (alength < blength)  {
          this.log(`resolving order by length for span starting at ${a.spanStartCharacter} (${alength} vs ${blength}) (1)`)
          return 1
        } else {
          this.log(`resolving order by length for span starting at ${a.spanStartCharacter} (${alength} vs ${blength}) (0)`)
          return 0
        }
      })
      let passage = this.taggedPassage
      let tempText = passage.html
      let spans = passage.spans
      let spansAndText = {spans:spans || [], text: tempText}
      this.log(tempText.length)
      spansAndText = this.replaceHtmlTagChars(spansAndText, "<", "&lt;")
      spansAndText = this.replaceHtmlTagChars(spansAndText, ">", "&gt;")
      this.taggedPassage.spans = spansAndText.spans
      this.taggedPassage.html = spansAndText.text
      this.log(this.taggedPassage.length)
    }
    this.initialised = true
    this.filterSpans()
  },
  computed: {
    tagNames: function () {
      return this.selectedTags.map(tag => tag["text"])
    },
    nothingFiltered : function () {
      return !((this.tagNames != null && this.tagNames.length)
        || (this.selectedGroupTags != null && this.selectedGroupTags.length))
    }
  },
  watch: {
    selectedTags: function() {
      this.filterSpans()
    },
    selectedGroupTags: function() {
      this.filterSpans()
    },
    groupTagRangeSelector: function() {
      this.filterSpans()
    }
  }
}
</script>
<style lang="scss" scoped>

  .passage-serial {
    color: #b4b4b4;
  }

  .mgm-card-header {
    border-bottom: 1px solid #a4a4a4;
  }
  .passage-mgm-card {
    border: unset;
  }


  div.card {
    border-radius: 0.25rem 0px 0.25rem 0.25rem;
  }
  
  .passage-header-right {
    line-height: 30px;
  }
  
  .library-card-tag {
    margin-left: -20px;

    span {
      margin-left: 15px;
    }
  }
  .selected-category-chip {
    background-color: var(--blue) !important;
    float: right;
    height: 24px;
    padding: 5px 8px;
    line-height: 16px;
  }

  .category-chip {
      background-color: var(--completed);
      color: white;
      border-radius: 5px;
      height: 24px;
      padding: 5px 8px;
      line-height: 16px;
      margin: 0px 4px;
      text-transform: capitalize;
  }
  @media print {
    .category-chip {
      color: black;
    }

  }
</style>
