<template>
  <div>
    <b-nav-item>
      <img v-if="node.childs[0]" class="collapse-arrow" :src="'/assets/images/icon_collapse.svg'" v-b-toggle="`${node.serial}`"/>
      <span @click="goTo(node.passageId)">{{node.label}}</span>
    </b-nav-item>
    <b-collapse :id="`${node.serial}`">
    <b-nav pills vertical v-for="child in node.childs" v-bind:key="child.serial">
      <b-nav-item>
        <document-index-node :node="child"></document-index-node>
      </b-nav-item>
    </b-nav>
      </b-collapse>
  </div>
</template>

<script>
export default {

  name: 'DocumentIndexNode',

  props: {
    node: Object
  },
  methods: {
    goTo(elemId) {
      document.getElementById(elemId).scrollIntoView({ behavior: 'smooth' }) 
    }
  }
}
</script>

<style lang="css" scoped>
</style>