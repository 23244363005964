<template>
  <div>
    <button class="button-secondary favourite-button" @click="setFavorite">
        <span class="openSans-100 fz-14 cursor-pointer padding-left-span" >
            <img class="margin-action-icons mx-0" src="/assets/images/icon_star_enabled.svg" v-if="passage.favorite" />
            <img class="margin-action-icons mx-0" src="/assets/images/icon_star_disabled.svg" v-else />
        </span>
    </button>
  </div>
</template>

<script>
import LibraryService from '@/modules/library/services/LibraryService.js'

export default {
  name: 'favourite-button',
  props: {
    passage: Object
  },
  methods: {
    setFavorite() {
      let currentFavorite = !this.passage.favorite
      LibraryService.setPassageFavorite(this.$router.currentRoute.params.bot, this.passage.id, currentFavorite).then(() => {
        this.passage.favorite = currentFavorite
        this.$emit('callback')
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.favourite-button {
  border-radius: 0px 6px 6px 0px !important;
  border: unset !important;
}

.button-secondary, .button-primary {
    min-width: unset !important;
    height: 30px !important;

    img {
        height: 24px;
        margin-right: 5px;
    }
}
</style>