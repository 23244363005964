<template>
  <div class="not-display" id="test" v-if="passage.feedback.length">
    <div class="col-12 mb-2" v-if="passage.feedback.length"><span >Comments:</span></div>
    <div class="col-12 comment" v-for="comment in passage.feedback" :key="comment.id">
      {{ comment.text }} - by {{getAuthorFormatted(comment.author)}}<br/>
      {{ formatDate(comment.created)}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'feedback-print',
  props: {
      passageSerial: Number,
      passage: Object
  },
  data() {
      return {
      }
  },
  methods: {
      getAuthorFormatted(author){
        return author.split("|")[1]
      },
      formatDate(str) {
        const date = new Date(str)
        return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`
      }
  },
  created() {
  }
}
</script>
<style lang="scss" scoped>

@media screen{
  .not-display{
    display:none !important
  }
}
@media print{
  .not-display{
    border:1px solid rgb(164, 164, 164);
    border-radius: 3px;
  }
  .comment {
    border-top :1px solid rgb(164, 164, 164);
  }
}

</style>