import Repository from '@/modules/common/utils/Repository'
import store from '@/store'

export default {

  getDocument(botId, docId, projectId, quantity) {
    let params = {'bot_name': botId, 'doc_id': docId, 'project_id': projectId}
    if (quantity != undefined) {
      params['quantity'] = quantity
    }
    return Repository.dbService.get('/li/get_tagged_document', {params: params})
  },

  getGraphData(botId, docId) {
    let params = {'bot_name': botId, 'doc_id': docId}
    return Repository.dbService.get('/li/get_tag_graph_data', {params: params})
  },

  getDocumentComparison(baseDocId, updatedDocId) {
    let params = {'base_doc_id': baseDocId, 'updated_doc_id': updatedDocId, 'bot_name': store.getters.getInstance}
    return Repository.dbService.get('/li/get_document_comparison', {params: params})
  },

  getOntologyGroups() {
    return Repository.dbService.get('/li/get_ontology_groups')
  },

  saveOntologies(ontologiesProject) {
    return Repository.dbService.post('/li/create_graph_from_project?bot_name='+store.getters.getInstance, ontologiesProject)
  },
  createMgmGraph(botName, projectId) {
    return Repository.dbService.post('/li/create_mgm_graph_from_project', {}, {
      params: {
        bot_name: botName,
        project_id: projectId
      }
    })
  },
  getMgmTaggedDocument(botName, docId, projectId) {
    return Repository.dbService.get('/li/get_mgm_tagged_document', {
      params: {
        doc_id: docId,
        project_id: projectId,
        bot_name: botName
      }
    })
  },
  getCategoryMetaTags(botName) {
    return Repository.dbService.get('/li/get_category_meta_tags', {
      params: {
        bot_name: botName
      }
    })
  }
}