<template>
  <div class="">
    <div class="ta-left px-0 mt-2 col-12 mgm-filter-header vertical-align not-print">
        <span class="fz-16 openSans-300">{{ $t('mgm.buttons.filters') }}</span>
        <span class="fz-16 ml-auto openSans-400 cursor-pointer align-items-baseline txt-viridian" @click="cancelFilter">{{ $t('mgm.buttons.clearAll') }}</span>
        <span class="fz-16 ml-2 openSans-400 cursor-pointer align-items-baseline txt-viridian" @click="applyFilter">{{ $t('mgm.buttons.applyFilter') }}</span>
    </div>

    <div class="scrollable-sidebar not-print">
        <div class="ta-left px-2 mt-2 pb-3 col-12 mgm-filter-section">
            <b-form-checkbox class="vertical-align favorites fz-16 openSans-300" v-model="filterFavorite" size="lg"> 
                <img class="margin-action-icons mx-0" src="/assets/images/icon_star_enabled.svg" /> Starred content only
            </b-form-checkbox>
            <span class="fz-14 d-block openSans-400 starred-disclaimer">If filters applied, displays content that are starred within the filtered content.</span>
        </div>


        <div class="ta-left px-2 pb-3 mt-3 col-12">
            <span class="d-block fz-16 openSans-300 mgm-filter-header-section-color">Category</span>
            <div>
                <multiselect v-model="selectedCategories" :selectLabel="''" :selectedLabel="''" :options="baseCategories" :multiple="true" :close-on-select="false"
                             :clear-on-select="false" :hide-selected="true" :preserve-search="true" placeholder="Select one or more" label="text" track-by="text"
                             @select="selectCategory" @remove="removeCategory">
                    <template slot="selection" slot-scope="{ values, isOpen }">
                        <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} categories selected</span>
                    </template>
                </multiselect>
            </div>
        </div>
        <div :class="selectedCategories.length == 0 ? 'mgm-filter-section' : '' " class="ta-left col-12 px-2 pb-3">
            <div v-if="selectedCategories.length != 0">
                <div class="col-12 px-0">
                    <div class="d-block mb-3" v-for="category in selectedCategories" :key="category.value">
                        <span class="d-block cursor-pointer mb-3" @click="category.displayRange = !category.displayRange">
                            <span class="fz-14 openSans-300 category-chip">{{ category.text }}</span>
                            <span class="fz-12 openSans-600 ml-2">{{ `${category.range[0]}% - ${category.range[1]}%` }}</span>
                        </span>
                        <vue-slider v-show="category.displayRange" tooltip="always" class="ce-slider px-4" v-model="category.range"></vue-slider>
                    </div>
                </div>
            </div>
        </div>
        <div :class="selectedCategories.length != 0 ? 'mgm-filter-section' : '' " class="ta-left col-12 px-2 pb-3">
            <div v-if="selectedCategories.length != 0">
                <div class="col-12 px-0">
                    <div class="d-flex justify-content-between">
                        <span class="fz-16 openSans-400 mr-2">Sort by Confidence Score</span>
                        <b-form-checkbox v-model="sortCriteria.sort" switch size="lg"></b-form-checkbox>
                    </div>
                    <b-form-radio-group v-if="sortCriteria.sort" v-model="sortCriteria.order" :options="orders" class="mt-2"></b-form-radio-group>
                </div>
            </div>
        </div>
        <div class="ta-left mt-2 px-2 pb-3 col-12 mgm-filter-section">
            <span class="d-block fz-16 openSans-300 mgm-filter-header-section-color">Group Tags</span>
            <b-form-checkbox v-for="metatag in metaTagsSource"
                             v-model="selectedGroupTags"
                             :key="metatag"
                             :class="metatag"
                             :value="metatag" >{{metatag.replace(/_/g," ")}}
            </b-form-checkbox>
            <div v-if="selectedGroupTags.length > 0" class="col-12 px-0">
                <span class="d-block fz-16 openSans-400 px-0">Confidence Score</span>
                <vue-slider tooltip="always" class="ce-slider px-4 pt-4" v-model="groupTagRangeSelector"
                            :aria-disabled="selectedGroupTags == []"></vue-slider>
            </div>
        </div>
        <div class="ta-left mt-2 px-2 mb-3 pb-3 col-12">
            <span class="d-block fz-16 openSans-300 mgm-filter-header-section-color">Tags</span>
            <vue-tags-input class="ask-tags-input autocomplete-container" v-model="tag" :tags="selectedTags"
                            :add-only-from-autocomplete="true" :autocomplete-items="filteredItems"
                            @tags-changed="newTags => selectedTags = newTags"
                            :placeholder="selectedTags.length > 0 ? '':'Add Tag'">
                <div slot="autocomplete-item" slot-scope="props" @click="props.performAdd(props.item)">
                    <div :class="`ti-tag ti-valid autocomplete-content ${ props.item.classes }`">
                        {{ props.item.text }}
                    </div>
                </div>
            </vue-tags-input>
        </div>
    </div>
    <div class="print not-display filter-box">
        <p>Filters Applied:</p>
        <p class="padding-left-40" v-if="sortCriteria.sort" >Sort by Confidence Score: {{sortCriteria.order}} </p>
        <p class="padding-left-20" v-if="selectedGroupTags.length" >Group Tags:</p>
        <div v-if="selectedGroupTags.length">
            <div class="padding-left-40 flex-tags">
                <b-form-checkbox v-for="metatag in selectedGroupTags" v-model="selectedGroupTags"
                                 :key="metatag" :class="metatag" :value="metatag" >
                    {{metatag.replace(/_/g," ")}}
                </b-form-checkbox>
            </div>
        </div>
        <p class="padding-left-20" v-if="selectedTags.length">Individual Tags:</p>
        <vue-tags-input class="ask-tags-input autocomplete-container padding-left-4" v-model="tag" :tags="selectedTags"
                        :add-only-from-autocomplete="true" :autocomplete-items="filteredItems"
                        @tags-changed="newTags => selectedTags = newTags">
                <div slot="autocomplete-item" slot-scope="props" @click="props.performAdd(props.item)">
                    <div :class="`ti-tag ti-valid autocomplete-content ${ props.item.classes }` ">
                        {{ props.item.text }} 
                    </div>
                </div>
            </vue-tags-input>
        <p class="padding-left-20">Starred passages only? {{filterFavorite?"Yes":"No"}}</p>
    </div>
  </div>
</template>

<script>
import LanguageIntelligenceService from '@/modules/languageInteligence/services/LanguageIntelligenceService.js'

export default {
  components: {
  },
  props: {
    categories: Array,
    rangeSelector: Array,
    metaTagsSource: Array,
    tagsSource: Array
  },
  data () {
    return {
      tag: '',
      selectedTags: [],
      filterFavorite: false,
      selectedGroupTags: [],
      categoriesWithTags: {},
      groupTagRangeSelector: [0,100],
      sortCriteria: {
        sort: false,
        order: 'descending'
      },
      baseCategories: [],
      selectedCategories: [],
      tagMessage: 'Display Tags',
      orders: [
        { value: 'ascending', text: 'Low to high' },
        { value: 'descending', text: 'High to low' }
      ]
    }
  },
  computed: {
    filteredItems() {
      return this.tagsSource.filter(i => {
        if(i.text) {
          return i.text.toLowerCase().split(" ").some(word => word.startsWith(this.tag.toLowerCase()));
         } else {
           return
         }
      })
    },
    selectedTagsText() {
      return this.selectedTags.map(tag => tag.text).join(", ")
    }
  },
  methods: {
    selectCategory(selected) {
        let groupTags = this.categoriesWithTags[selected.value]
        if (groupTags) {
            groupTags.forEach(tag => {
                if (!this.selectedGroupTags.includes(tag)) {
                    this.selectedGroupTags.push(tag)
                }
            })
        }
    },
    removeCategory(removed) {
        const index = this.selectedGroupTags.indexOf(removed.value)
        if (index > -1) {
            this.selectedGroupTags.splice(index, 1)
        }
    },
    applyFilter() {
      this.$emit('update:filtered', true)
      this.$emit('update:selectedCategories', this.selectedCategories)
      this.$emit('update:sortCriteria', this.sortCriteria)
      this.$emit('update:selectedGroupTags', this.selectedGroupTags)
      this.$emit('update:selectedTags', this.selectedTags)
      this.$emit('update:filterFavorite', this.filterFavorite)
      this.$emit('update:groupTagRangeSelector', this.groupTagRangeSelector)
      this.$emit('applyFilter')
    },
    cancelFilter() {
        this.createBaseCategories()
        this.selectedCategories = []
        this.sortCriteria.sort = false
        this.sortCriteria.order = 'descending'
        this.selectedGroupTags = []
        this.selectedTags = []
        this.groupTagRangeSelector = [0, 100]
        this.filterFavorite = false
        this.applyFilter()
        this.$emit('update:filtered', false)
    },
    getCategoryTags() {
        LanguageIntelligenceService.getCategoryMetaTags(this.$router.currentRoute.params.bot).then(response => {
            this.categoriesWithTags = response.data
        })
    },
    createBaseCategories() {
        this.baseCategories = this.categories.map(category => {
            return {
                text: category.replace(/_/g," "),
                value: category,
                range: [0,100],
                displayRange: false
            }
        })
    }
  },
  created() {
    this.getCategoryTags()
    this.createBaseCategories()
    this.minSelector = 0
    this.maxSelector = 100
  }
}
</script>

<style lang="scss" scoped>

    .category-chip {
        background-color: var(--completed);
        padding: 4px;
        border-radius: 4px;
        color: white;
    }
    .autocomplete-content {
        width: fit-content;
    }
    .title {
      text-transform: capitalize;
    }
    @media print {
      .not-print{
        display:none
      }

      .print{
        display:block!important
      }
    }

    @media display {
    }  

      .not-display{
        display:none ;
      }

    .scrollable-sidebar {
        max-height: 94vh;
        height: auto;
        min-height: 740px;
        /*overflow: scroll;*/
        overflow-x: hidden;
    }

    .mgm-filter-header-section-color {
        color: var(--black);
    }

    .padding-left-20 {
        padding-left:20px;
    }

    .padding-left-40 {
        padding-left:40px;
    }

    .filter-box{
        text-align: left; padding-left: 30px; 
    }

    .mgm-filter-section {
        border-bottom: 1px solid var(--gray);
    }

    .starred-disclaimer {
        color: #c4c4c4;    
    }

    .mgm-filter-header {
        background-color: #f8f8f8;
        border-bottom: 1px solid #c4c4c4;
        margin-left: 0px;
        margin-top: 0px !important;
        padding: 8px !important;
    }

    .filter-button-adjust {
        min-width: unset !important
    }

    .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
        color: #fff;
        background-color: var(--viridian);
    }

    button.button-primary, button.button-secondary {
        width: 100% !important
    }

    button.button-primary > img.inversed-svg {
        filter: grayscale(100%) brightness(400%);
        margin-right:10px;
    }

    .ask-tags-input {
        margin-bottom: 10px;
        min-height: unset;
    }

    .mgm-filter-title {
        color: var(--ocean-blue)
    }

    .ce-slider {
        margin: 20px 0px;
    }

    .ce-content-select {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    ::v-deep {

        .multiselect__option--highlight, .multiselect__tag  {
            background-color: var(--viridian)
        }
        
        .ce-slider {
            margin-top: 50px !important;
        }

        @media print {
            div.vue-tags-input div.ti-input{
                border: 0px;
                padding-left: 40px;
            }

            ul.ti-tags li.ti-new-tag-input-wrapper{
                display: none
            }

            i.ti-icon-close{
                display: none
            }

            label.custom-control-label::after{
                display: none
            }

            label.custom-control-label::before{
                display: none
            }

            div.custom-control.custom-checkbox{
                padding-left:4px !important
            }

            div.flex-tags{
                display:flex !important
            }
        }

        

        .vue-slider-dot-tooltip-inner {
            background-color: var(--viridian);
            border-color: var(--viridian)
        }

        .vue-slider-process {
            background-color: var(--viridian);
        }

        footer.modal-footer {
            justify-content: space-between;
        }

        div.modal-dialog {
            max-width: 1207px;
            height: auto;
            max-height: 80vh;
        }

        div.modal-backdrop {
            background-color: rgba(255,255,255,0.55);
        }

        header.modal-header {
            border-bottom: none;
            padding-bottom: 0px;
        }

        .custom-checkbox {
            color: var(--dark);
            text-transform: capitalize;

        }

        .custom-control-label span, span.cursor-pointer {
            font-family: OpenSans;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--dark);
        }

        .custom-control-label::before {
            border: 1px solid var(--viridian);
            border-radius: 6px;
        }

        .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
            background-color: var(--viridian);
            border-radius: 6px;
        }

        .custom-control-input:checked + label.custom-control-label span,
        .custom-control.custom-checkbox .custom-control-input:checked + span.cursor-pointer {
            color: black;
        }

    }


</style>
