<template>
    <div print-full-size>
      <div v-if="selectedDocument" class="hidde-div  pt-2 pb-2">
        <div>Document Created by {{$auth.user.name}} at {{this.getDateTimeZone()}} (GMT)</div>
      </div>
      <div v-if="selectedDocument" class="hidde-div name-header pt-2 pb-2">
        <div>Document Title: {{this.selectedDocument.taggedDocument.title}}</div>
      </div>
        <preloader v-if="!pipelineReady" type="modal" class="mt-5"/>
        <div v-else class="ta-left not-print">
            <span class="d-block fz-16 openSans-300">Select document</span>
            <v-select :placeholder="$t('li.document.selectDocument')"
            label="title"
            :title="selectedDocument ? selectedDocument.taggedDocument.title : 'Please, select a document'"
            v-model="selectedDocumentId"
            :reduce="document => document.doc_id"
            :options="documents"
            class="document-select w-40 mgm-document-select">
              <template :slot-scope="option" v-slot:option="document">
                  <span :key="document.doc_id" :title="document.title" :value="document.doc_id">{{document.title}} </span>
              </template>
            </v-select>
        </div>
        <div v-if="loadingDocument" class="d-flex justify-content-center mt-5">
          <b-spinner></b-spinner>
        </div>
        <b-tabs v-if="selectedDocument" class="col-12 pl-0 mt-2 inside-tabs">
            <b-tab title="Overview" disabled class="not-print">
              <template v-slot:title class="not-print">
                <div class="not-print">
                  <span>{{ $t('mgm.tabs.contentExplorerTab.tabs.overview') }}</span>
                </div>
              </template>
            </b-tab>
            <b-tab title="Viewer" >
              <template v-slot:title>
                <div class="not-print">
                  <span>{{ $t('mgm.tabs.contentExplorerTab.tabs.viewer') }}</span>
                </div>
              </template>
                <b-row class="mgm-toolbar mb-2 mx-0 not-print">
                  <b-col class="text-left my-2">
                    <b-button :title="showIndex ? 'Hide Index' : 'Show Index'" :class="showIndex ? 'button-primary white-icon' : 'button-secondary clean-border'"
                      class="icon-button col-12" @click="showWarning(displayIndex, filtered)" :disabled="!haveIndex">
                      <img class="mgm-toolbar-icon mx-0" src="/assets/images/icon_mgm_index.svg" />
                    </b-button>
                    <b-button :title="showTags ? 'Hide Tags' : 'Show Tags'" :class="showTags ? 'button-primary white-icon' : 'button-secondary clean-border'"
                              class="icon-button col-12 ml-1" @click="displayTags">
                      <img class="mgm-toolbar-icon mx-0" src="/assets/images/icon_mgm_tags.svg" />
                    </b-button>
                    <b-button
                      :title="showFilters ? 'Hide Filters' : 'Show Filters'" :class="showFilters ? 'button-primary white-icon' : 'button-secondary clean-border'" class="icon-button col-12 ml-1"
                      @click="showWarning(displayFilters, filtered)">
                      <img class="mgm-toolbar-icon mx-0" src="/assets/images/icon_mgm_filters.svg" />
                    </b-button>
                    <b-button
                      title="Download" class="icon-button col-12 ml-1 button-secondary clean-border"
                      @click="download()">
                      <img class="mgm-toolbar-icon mx-0" src="/assets/images/icon_download.svg" />
                    </b-button>
                  </b-col>
                  <b-col class="text-right my-2">
                    <b-button title="Clear stars from all passages" class="button-secondary clean-border icon-button col-12 align-icon ml-auto"
                              :disabled="!allFavoriteValue" @click="showWarning(unstarAll, true, 'This will clear all stars applied to passages in the document. Do you want to proceed?')">
                      <img class="mgm-toolbar-icon mx-0" :src="allFavoriteValue ? '/assets/images/icon_unstar.svg' : '/assets/images/icon_unstar_disabled.svg' " />
                    </b-button>
                  </b-col>
                </b-row>

                <b-row>
                  <div class="col-3 mb-10 print-col-3" v-if="showFilters">
                    <div class="card sticky-filters">
                      <filter-column
                      :selectedCategories.sync="selectedCategories"
                      :filtered.sync="filtered"
                      :selectedGroupTags.sync="selectedGroupTags"
                      :selectedTags.sync="selectedTags"
                      :filterFavorite.sync="filterFavorite"
                      :categories.sync="categories"
                      :rangeSelector.sync="rangeSelector"
                      :metaTagsSource.sync="metaTags"
                      :tagsSource.sync="tags"
                      :groupTagRangeSelector.sync="groupTagRangeSelector"
                      :sortCriteria.sync="sortCriteria"
                      @displayTags="displayTags"
                      @applyFilter="filterPassages">
                      </filter-column>
                    </div>
                  </div>
                  <div class="col-3 mb-10" v-if="showIndex">
                    <div class="card sticky-filters">
                      <document-index :passages="filteredPassages"></document-index>
                    </div>
                  </div>
                  <div :class="(showFilters || showIndex) ? 'col-9 print-col-9' : 'col-12'" class="passage-top">
                    <div class="row">
                      <div class="ta-left col-10">
                      <span class="fz-18 openSans-300 blue-text">{{projectTitle}} -
                        <span class="fz-18 openSans-400 blue-text">{{this.selectedDocument.taggedDocument.title}}</span>
                      </span>
                      </div>
                      <div class="col-2 pl-3 pr-3 pt-0">
                        <span class="fz-14 openSans-400 passages-count">{{filteredPassages.length}} / {{passages.length}} Passages Displayed</span>
                      </div>
                    </div>
                    <div class="pr-0" v-for="passage in filteredPassages" v-bind:key="passage.serial" :id="`passage_${passage.serial}`">
                      <document-passage v-bind:passage="passage" :selectedCategories="selectedCategories"
                                        :selectedTags="selectedTags" :selectedGroupTags="selectedGroupTags"
                                        :showTags="showTags" :groupTagRangeSelector="groupTagRangeSelector" :favorite-callback="setAllFavoriteValue">
                      </document-passage>
                    </div>
                  </div>
                </b-row>
            </b-tab>
            <b-tab title="Summary" disabled class="not-print">
                <template v-slot:title>
                  <div class="not-print">
                    <span>{{ $t('mgm.tabs.contentExplorerTab.tabs.summary') }}</span>
                  </div>
                </template>
            </b-tab>
        </b-tabs>

        <b-modal ref="warning-modal"  hide-footer hide-title>
          <div class="d-block text-center">
            <h4 class="fz-16 openSans-500">{{this.warningMessage}}</h4>
          </div>
          <div class="d-flex justify-content-around mt-4">
            <b-button class="button-secondary" @click="hideWarning">No</b-button>
            <b-button class="button-primary ml-3" @click="warningCallback">Yes</b-button>
          </div>
        </b-modal>

    </div>
</template>

<script>
import LanguageIntelligenceService from '@/modules/languageInteligence/services/LanguageIntelligenceService'
import ProjectService from '@/modules/projects/services/ProjectService'
import LibraryService from '@/modules/library/services/LibraryService.js'

import DocumentPassage from '@/modules/medicalGuidelineManagement/components/DocumentPassage'
import FilterColumn from '@/modules/medicalGuidelineManagement/components/FilterColumn'
import Preloader from '@/modules/common/components/Preloader.vue'
import DocumentIndex from '@/modules/common/components/DocumentIndex.vue'
import moment from 'moment'

const $ = require('jquery')
window.$ = $

function sumAllScoresBy(categories, passage) {
  return categories.reduce((accumulator, current) => {
    let score = passage.predictioScores[current.value]
    return accumulator + (score ? score : 0)
  }, 0)
}

export default {
  name: "ContentExplorer",
  components: { DocumentPassage, FilterColumn, Preloader, DocumentIndex },
  data () {
    return {
        projectId: null,
        selectedDocumentId: null,
        selectedDocument: null,
        passages: [],
        filteredPassages: [],
        sortCriteria: {},
        sortOrderFunc: {
          descending: function(categories) {
            return (p1, p2) => {
              return sumAllScoresBy(categories, p2) - sumAllScoresBy(categories, p1)
            }
          },
          ascending: function(categories) {
            return (p1, p2) => {
              return sumAllScoresBy(categories, p1) - sumAllScoresBy(categories, p2)
            }
          }
        },
        filtered: false,
        showTags: false,
        showFilters: false,
        showIndex: false,
        haveIndex: false,
        pipelineReady: false,
        loadingDocument: false,
        filterFavorite: false,
        documents: [],
        rangeSelector: [0,100],
        selectedCategory: null,
        selectedGroupTags: [],
        selectedTags: [],
        groupTagRangeSelector: [0,100],
        selectedCategories: [],
        metaTags: [],
        tags: [],
        categories: [],
        pollingDocument: null,
        pollingMgmGraph: null,
        colorPalette: ['#e66609','#0d25ff','#9002e8','#ff0162','#19ffa2','#23b8eb','#8c21eb','#79ff30'],
        mgmOntologyColors: null,
        warningCallback: null,
        allFavoriteValue: false,
        projectTitle: 'Project title',
        warningMessage : "Performing this action will clear all filters. Do you wish to proceed?"
    }
  },
  watch: {
    selectedDocumentId(selectedDocument) {
      // Reset current polling for each document select
      this.showTags = true
      this.showFilters = false
      this.showIndex = false
      this.hideWarning()
      this.resetFilters()
      this.filtered = false
      clearInterval(this.pollingDocument)
      this.pollingDocument = null
      this.passages = []
      this.selectedDocument = null
      this.loadingDocument = true
      LanguageIntelligenceService.getMgmTaggedDocument(this.$router.currentRoute.params.bot, selectedDocument, this.projectId).then(response => {
        if (response.data.status == "QUEUED") {
          this.pollDocumentData(this.$router.currentRoute.params.bot, selectedDocument)
        } else {
          this.loadingDocument = false
          this.selectedGroupTags = []
          this.useDocumentData(response.data)
        }
      })
    }
  },
  methods: {
    download() {
      window.print()
    },
    sortPassages() {
      if (this.sortCriteria.sort) {
        let sortFunc = this.sortOrderFunc[this.sortCriteria.order]
        var orderedList = this.filteredPassages
        orderedList = orderedList.sort(sortFunc(this.selectedCategories))
        this.filteredPassages = orderedList
      }
    },
    useDocumentData(documentData) {
      this.selectedDocument = documentData
      this.mgmOntologyColors = documentData.mgmOntologyColors
      this.passages = this.selectedDocument.taggedDocument.passages
      this.filteredPassages = this.passages
      this.haveIndex = this.documentHaveIndex()
      this.setAllFavoriteValue()
      this.setCategories()
      this.setTagGroups()
      this.setTags()
      this.setTagsColours()
    },
    setAllFavoriteValue() {
      const and = (accumulator, currentValue) => accumulator || currentValue.favorite
      this.allFavoriteValue = this.passages.reduce(and, false)
    },
    getDateTimeZone(){
      let date = new Date()
      let today = moment(date).format('HH:mm Z')
      return `${ today }`
    },
    documentHaveIndex() {
      var haveIndex = false
      var i = 0
      while (!haveIndex) {
        haveIndex = haveIndex || (this.passages[i] != undefined && this.passages[i].heading != undefined)
        if (i > this.passages.length)
          break;
        i++
      }
      return haveIndex
    },
    pollDocumentData(botName, docId) {
      this.pollingDocument = setInterval(() => {
        LanguageIntelligenceService.getMgmTaggedDocument(botName, docId, this.projectId).then(response => {
          if (response.data.taggedDocument) {
            this.loadingDocument = false
            this.useDocumentData(response.data)
            clearInterval(this.pollingDocument)
            this.pollingDocument = null
          }
        })
      }, 4000)
    },
    setTagGroups(){
      this.metaTags = Object.keys(this.selectedDocument.taggedDocument.metaTags)
    },
    setTags(){
      this.tags = this.selectedDocument.taggedDocument.autocompleteEntries
    },
    setCategories(){
        var taggedDocument = this.selectedDocument.taggedDocument;
        this.categories = taggedDocument.predictionLabels
    },
    createClass(name,rules){
      var style = document.createElement('style');
      style.type = 'text/css';
      document.getElementsByTagName('head')[0].appendChild(style);
      if(!(style.sheet||{}).insertRule)
          (style.styleSheet || style.sheet).addRule(name, rules);
      else
          style.sheet.insertRule(name+"{"+rules+"}",0);
    },
    setTagsColours() {
      this.metaTags.forEach(metaTag => {
        let isMetaTagColorDefined = this.root.style.getPropertyValue(`--${ metaTag }`).length != 0
        if (!isMetaTagColorDefined) {
          let currentColor = this.getMetaTagColor(metaTag)
          this.root.style.setProperty(`--${ metaTag }`, currentColor)
          this.createClass(`.li-tagged-text.${ metaTag }`,
                           `border: 1px solid var(--${ metaTag }); background-color: ${ this.getRGBA(currentColor) }`)
          this.createClass(`.li-tag.${ metaTag }`,
                           `background-color: ${ currentColor }`)
          this.createClass(`.category-chip.fz-14.openSans-400.${ metaTag }`,
                           `background-color: var(--${ metaTag }); color: #fff`)
          this.createClass(`div.custom-control.custom-checkbox.${ metaTag } label.custom-control-label`,
                           `border: 1px solid var(--${ metaTag }); background-color: ${ this.getRGBA(currentColor) }`)
          this.createClass(`.ti-tag.ti-valid.${ metaTag }`,
                           `color: var(--dark); border: 1px solid var(--${ metaTag }); background-color: ${ this.getRGBA(currentColor) }`)
        }
      })
    },
    getMetaTagColor(metaTag) {
      var color = this.mgmOntologyColors[metaTag]
      if (!color) {
        color = this.colorPalette.shift()
      }
      return color
    },
    getRGBA(hexColor){
        let hex = hexColor.replace('#','')
        const r = parseInt(hex.substring(0,2), 16)
        const g = parseInt(hex.substring(2,4), 16)
        const b = parseInt(hex.substring(4,6), 16)
        return `rgba(${r}, ${g}, ${b}, 0.3)`
    },
    initialDocument() {
      let params = this.$router.currentRoute.params
      this.projectId = params.projectId
      ProjectService.get(params.projectId, params.bot).then(response => {
        let project = response.data
        if(!this.hasMedicalGuidelines(project)) {
          this.pollMgmGraph(params.bot, params.projectId)
        } else {
          this.pipelineReady = true
        }
        this.documents = project.documents
      })
    },
    pollMgmGraph(botName, projectId) {
      this.pollingMgmGraph = setInterval(() => {
        LanguageIntelligenceService.createMgmGraph(botName, projectId).then(response => {
          this.pipelineReady = response.data.status != 'PENDING'
          if (this.pipelineReady) {
            clearInterval(this.pollingMgmGraph)
            this.pollingMgmGraph = null
          }
        })
      }, 4000)
    },
    hasMedicalGuidelines(project) {
      var hasMGPipeline = false
      project.pipeline_configs.forEach(pipelineConfig => {
        if (pipelineConfig.name == "medical_guidelines" &&
            pipelineConfig.status == 'COMPLETED') {
          hasMGPipeline = true
        }
      })
      return hasMGPipeline
    },
    showWarning(callback, condition, message) {
      if (message) {
        this.warningMessage = message
      } else {
        this.warningMessage = "Performing this action will clear all filters. Do you wish to proceed?"
      }
      if (condition) {
        this.warningCallback = callback
        this.$refs['warning-modal'].show()
      } else {
        callback()
      }
    },
    hideWarning() {
      this.$refs['warning-modal'].hide()
    },
    unstarAll() {
      LibraryService.setAllFavorite(this.$router.currentRoute.params.bot,
                                    this.selectedDocument.taggedDocument.doc_id, false).then(() => {
        this.hideWarning()
        this.allFavoriteValue = false
        this.uploadFavoriteValues(false)
      })
    },
    uploadFavoriteValues(value) {
      this.passages.forEach(passage => {
        passage.favorite = value
      })
    },
    displayFilters() {
      this.hideWarning()
      this.resetFilters()
      this.showIndex = false
      this.showFilters = !this.showFilters
      this.filtered = false
    },
    displayIndex() {
      this.hideWarning()
      this.resetFilters()
      this.showFilters = false
      this.showIndex = !this.showIndex
      this.filtered = false
    },
    displayTags() {
      this.showTags = !this.showTags
      if(this.showTags) {
        $(".li-tagged-text.tags-invisible").removeClass("tags-invisible")
      } else {
        $(".li-tagged-text").addClass("tags-invisible")
      }
    },
    resetFilters() {
      this.selectedCategory = null
      this.selectedGroupTags = null
      this.selectedTags = []
      this.filterFavorite = false
      this.filterPassages()
    },
    predictionScoreInRange(value, range) {
      return Math.round(value* 100)  >= range[0] && Math.round(value* 100) <= range[1]
    },
    filterPassages() {
      this.filteredPassages = this.passages.filter(p => this.passageFilter(p))
      this.sortPassages()
    },
    passageFilter(passage) {
      /* Show all if there is not category or group tag selected */
      let categoryFilter = true
      let groupFilter = true
      let tagFilter = true
      let spanFilter = true
      let favorite = true

      if (!this.selectedCategory &&
          (!this.selectedGroupTags || this.selectedGroupTags.length == 0) &&
          (!this.selectedTags || this.selectedTags.length == 0) && !this.filterFavorite)
        return true;

      /* Show if there is a selected category and the prediction score is in range */
      if (this.selectedCategories.length != 0) {
        var score = 0
        categoryFilter = true
        this.selectedCategories.forEach(category => {
          score = passage.predictioScores[category.value]
          categoryFilter = categoryFilter && (score && this.predictionScoreInRange(score, category.range))
        })
      }

      if (this.filterFavorite) {
        favorite = passage.favorite
      }

      /* Show if there is a selected tag included in the passage */
      if (this.selectedTags && this.selectedTags.length) {
        let filterByTags = { text: [], tag: [] }
        this.selectedTags.forEach(tagData => {
          filterByTags[tagData.type].push(tagData)
        })
        if (filterByTags.tag.length) { //&& passage.spans && passage.spans.length
          tagFilter = passage.spans.some(span => filterByTags.tag.some(tagData =>
            tagData.classes == span.category && tagData.text == span.synonym.toLowerCase()))
        }
        if (filterByTags.text.length ) {//&& passage.spans && passage.spans.length
          spanFilter = passage.spans.some(span => filterByTags.text.some(tagData =>
            tagData.classes == span.category && tagData.text == span.text.toLowerCase()))
        }
      }

      /* Show if there is a selected group tag in the passage */
      if (this.selectedGroupTags && this.selectedGroupTags.length) {
        groupFilter = false;
        if (!passage.group_tags) {
          groupFilter = false;
        } else {
          for (const group of this.selectedGroupTags) {
            for (const span of passage.spans) {
              if (span.tags[0]["propMetaTagName"] == group && this.spanScoreInRange(span.predictionScore) ) {
                groupFilter = true;
                break;
              }
            }
          }
        }
      }
      return categoryFilter && groupFilter && tagFilter && spanFilter && favorite;
    },
    spanScoreInRange(score) {
      return score * 100 >= this.groupTagRangeSelector[0] && score * 100 <= this.groupTagRangeSelector[1]
    },
    getAuthorFormatted(author){
      return author.split("|")[1]
    }
  },
  mounted: function() {
    this.root = document.documentElement
  },
  beforeDestroy() {
    clearInterval(this.pollingDocument)
    clearInterval(this.pollingMgmGraph)
  },
  created() {
    this.initialDocument();
  }
}
</script>

<style lang="scss" scoped>

    @media print {
      .not-print{
        display:none
      }

      .print-full-size{
        width:100%
      }

      .passage-top{
        border-top:1px solid #a4a4a4;
        margin-top: 10px;
      }

      .print-col-3{
        flex: 0 0 100% !important;
        max-width:100% !important;
      }

      .print-col-9{
        flex: 0 0 100% !important;
        max-width:100% !important;
      }
    }

    @media screen{
      .hidde-div{
        display:none
      }


    }
    .passages-count {
      color: #b4b4b4;
    }

    .mgm-toolbar {
      border: 1px solid #c4c4c4;
      background-color: #f8f8f8;
      border-radius: 7px;
    }

    .white-icon {
        img {
          filter: brightness(10)
        }
    }

    .clean-border {
      border: unset !important;
    }

    .align-icon {
      align-items: center;
      justify-content: center;
      display: inline-flex;
    }

    .blue-text {
      color: var(--ocean-blue);
    }

    .mgm-toolbar-icon {
      height: auto;
      width: 100%;
    }

    .name-header{
      border-bottom:1px solid #a4a4a4
    }

    .icon-button {
        min-width: 52px !important;
    }

    .document-select {
        width: 56%;
        color: #28a745;
        border-color: #28a745;
        margin-top: 10px;
    }

    .full-height{
        min-height:90vh;
    }
    .tabs-menu {
        margin-left: -35px;
    }

    .padding-tabs {
        margin-left: 10px;
    }

    ::v-deep {

        div.custom-control.custom-checkbox.favorites label.custom-control-label {
            color: var(--black);
            padding: 2px 6px;
            font-size: 16px;
            line-height: 24px;
        }

        .mgm-document-select  {
            z-index: 1021;

            span.vs__selected {
                    color: var(--viridian);
                    width: 630px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
            }
            ul.vs__dropdown-menu li {
                    width: 700px;
                    display: inline-block;
                    white-space: inherit;
            }
        }

        .li-tag.tags-invisible {
            background-color: transparent !important;
            color: #2c3e50 !important;
            padding: none !important;
            border-radius: 0px !important;
            border: unset !important;
            display: none !important;
        }

        .li-tagged-text.tags-invisible {
            background-color: transparent !important;
            color: #2c3e50 !important;
            padding: none !important;
            border-radius: 0px !important;
            border: unset !important;
        }

        .li-tagged-text, div.custom-control.custom-checkbox label.custom-control-label{
            color: var(--black);
            padding: 2px 6px;
            border-radius: 5px;
        }

        .li-tag{
            padding: 1px 4px;
            border-radius: 5px;
            color: white;
        }

        div.custom-control.custom-checkbox {
          margin: 5px 0px
        }

        div.inside-tabs > div > ul.nav-tabs {
            border-bottom: none;
            border-top: none;
            margin-left: 0px;
            background-color: transparent;

        li.nav-item:first-of-type {
            margin-left: 0px;
        }

        li.nav-item a.nav-link {
            color: var(--dark);
            margin-right: 0px;
            min-width: unset;

            span {
                color: var(--dark);
                padding-left: 0px;
            }
        }

        li.nav-item a.nav-link.active {
            color: var(--viridian);
            border: none;
            border-bottom: 1px solid var(--viridian);
            font-weight: 300;

            span {
                padding-left: 0px;
                color: var(--viridian)
            }
        }
    }
}

</style>
