<template>
  <div class="container-base section-container-padding bg-white">
    <div class="myproj-container ">
      <b-row>
        <b-tabs class="col-12 " content-class="mt-3 ">
          <b-tab title="Project Overview" disabled>
            <template v-slot:title>
              <div class="no-display">
                
              <img :src="'/assets/images/icon_di_ingestedlist.svg'"/><span>{{ $t('mgm.tabs.projectOverview') }}</span>
              </div>
            </template>
          </b-tab>
          <b-tab title="Content Explorer">
            <template v-slot:title>
              <div class="no-display">
                <img :src="'/assets/images/icon_li_ingestion.svg'"/><span>{{ $t('mgm.tabs.contentExplorer') }}</span>
              </div>
            </template>
            <content-explorer></content-explorer>
          </b-tab>
          <b-tab title="Content Comparison"  style="display: none" disabled>
            <template v-slot:title style="display: none" >
              <div class="no-display">
              <img :src="'/assets/images/icon_li_ingestion.svg'"/><span>{{ $t('mgm.tabs.contentComparison') }}</span>
              </div>
            </template>
          </b-tab>
        </b-tabs>
      </b-row>
    </div>
  </div>
</template>

<script>
import ContentExplorer from '@/modules/medicalGuidelineManagement/views/ContentExplorer'

export default {
  name: "MedicalGuidelineManagement",
  components: {ContentExplorer},
  data () {
    return {
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

  .header-margin {
      margin: 20px 0px 30px 10px
  }

  .myproj-container ul.nav.nav-tabs {
    display:none !important;
  }

  .document-select {
    width: 40%;
    color: #28a745;
    border-color: #28a745;
    margin-top: 10px;
  }

  @media print{

    .no-display{
      display:none
    }
  }

::v-deep {
    div.inside-tabs div ul.nav-tabs {
    border-bottom: none;

    li.nav-item a.nav-link {
        color: var(--dark)
    }

    li.nav-item a.nav-link.active {
        color: var(--viridian);
        border: none;
        border-bottom: 1px solid var(--viridian)
    }
}
}

</style>
